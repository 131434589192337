import React from 'react'
import Modal from '@mui/material/Modal'
import './Popup.css'
import { useRecoilState } from 'recoil'
import { dialogPopUp } from '../../recoil/atoms/atoms'

const Popup = ({ popContent, className }) => {
  const [open, setOpen] = useRecoilState(dialogPopUp)
  return (
    <div>
      <Modal className={className} open={open}>
        <div className={`popup-container ${className}`}>{popContent}</div>
      </Modal>
    </div>
  )
}

export default Popup
