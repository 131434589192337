import * as React from 'react'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import '../autoComplete/AutoComplete.css'
import createTheme from '@mui/material/styles/createTheme'
import ThemeProvider from '@mui/material/styles/ThemeProvider'
import useTheme from '@mui/material/styles/useTheme'

import { outlinedInputClasses } from '@mui/material/OutlinedInput'

const customTheme = (outerTheme) =>
  createTheme({
    palette: {
      mode: outerTheme.palette.mode,
    },
    components: {
      MuiAutocomplete: {
        styleOverrides: {
          root: {
            width: '100%',
            // Other styles for the Autocomplete root element
          },
          option: {
            // Customize options in the dropdown
            color: '1px solid rgba(22, 43, 72, 0.23)',
            '&.Mui-focused': {
              backgroundColor: outerTheme.palette.action.hover,
            },
          },
          paper: {
            // Customize the dropdown menu
            boxShadow: outerTheme.shadows[1],
            borderRadius: 4,
            border: `1px solid ${outerTheme.palette.divider}`,
          },
        },
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            '& label.Mui-focused': {
              color: outerTheme.palette.primary.main,
            },
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            '&:hover .${outlinedInputClasses.notchedOutline}': {
              borderColor: outerTheme.palette.primary.light,
            },
            '&.Mui-focused .${outlinedInputClasses.notchedOutline}': {
              borderColor: outerTheme.palette.primary.main,
            },
            '&.Mui-error .${outlinedInputClasses.notchedOutline}': {
              borderColor: outerTheme.palette.error.main,
            },
          },
        },
      },
    },
  })

export default function AutoCompleteComponent({
  multiple,
  options,
  label,
  placeholder,
  size,
  initialValue, // Add initialValue prop
  selectChangeHandler, // Assuming selectChangeHandler is defined elsewhere
  onSelect,
  clearSelectedValue,
  error,
  className,
  disabled,
  errortext,
  dashBoardClear = 'false',
  ...rest
}) {
  const outerTheme = useTheme()
  const [clearedIcon, setClearedIcon] = React.useState(false)
  const [selectedOptions, setSelectedOptions] = React.useState(
    initialValue?.Name?.length > 0 ? initialValue : null
  )
  const handleSelectChange = (event, newValue) => {
    setSelectedOptions(newValue)
    if (newValue && dashBoardClear === 'true') {
      setClearedIcon(false)
    } else if (newValue && dashBoardClear === 'false') {
      setClearedIcon(true)
    } else if (!newValue && dashBoardClear === 'false') {
      setClearedIcon(false)
    }
    if (selectChangeHandler) {
      selectChangeHandler(event, newValue, rest)
    }
  }
  React.useEffect(() => {
    if (clearSelectedValue) {
      setSelectedOptions('')
    }
  }, [clearSelectedValue])
  React.useEffect(() => {
    setSelectedOptions(initialValue)
  }, [initialValue])
  return (
    <div
      // className="autoComplete_main"
      className={`autoComplete_main TextFieldParent ${
        error && 'TextFieldError'
      } ${className}`}
    >
      <ThemeProvider theme={customTheme(outerTheme)}>
        <div class="autolabel-name">{label}</div>
        <Autocomplete
          sx={{ width: 300 }}
          options={options || []}
          getOptionLabel={(option) => option?.Name || ''}
          onChange={handleSelectChange}
          value={selectedOptions}
          size={size}
          disableClearable={!clearedIcon}
          renderInput={(params) => (
            <TextField
              error={error}
              helperText={error && errortext && errortext}
              focused
              placeholder={placeholder}
              {...params}
              inputProps={{
                ...params.inputProps,
                readOnly: true, // Prevent typing
              }}
              size="small"
            />
          )}
          onSelect={onSelect}
          disabled={disabled}
        />
      </ThemeProvider>
    </div>
  )
}
