import React, { useEffect, useState } from 'react'
const RichTextEditor = React.lazy(() => import('react-rte'))
import './GlobalEditor.css'
import PropTypes from 'prop-types'
import { Typography } from '@mui/material'

// toolbarConfig.js
export const toolbarConfig = {
  display: [
    'INLINE_STYLE_BUTTONS',
    'BLOCK_TYPE_BUTTONS',
    'LINK_BUTTONS',
    // 'BLOCK_TYPE_DROPDOWN',
    'HISTORY_BUTTONS',
  ],
  INLINE_STYLE_BUTTONS: [
    { label: 'Bold', style: 'BOLD', className: 'custom-css-class' },
    { label: 'Italic', style: 'ITALIC' },
    { label: 'Underline', style: 'UNDERLINE' },
  ],
  // BLOCK_TYPE_DROPDOWN: [
  // { label: 'Normal', style: 'unstyled' },
  // { label: 'Heading Large', style: 'header-one' },
  // { label: 'Heading Medium', style: 'header-two' },
  // { label: 'Heading Small', style: 'header-three' },
  // ],
  BLOCK_TYPE_BUTTONS: [
    { label: 'UL', style: 'unordered-list-item' },
    { label: 'OL', style: 'ordered-list-item' },
  ],
}

const GlobalEditor = ({ label, onChange, value, error, name }) => {
  const [editorValue, setEditorValue] = useState(
    RichTextEditor.createEmptyValue()
  )

  useEffect(() => {
    if (value) {
      setEditorValue(value)
    }
  }, [value])

  const handleChange = (newValue) => {
    setEditorValue(newValue)
    if (onChange) {
      onChange(newValue)
    }
  }

  return (
    <div className="editor-wrapper">
      <Typography variant="label" color="#162B4899">
        {label}
      </Typography>
      <RichTextEditor
        value={editorValue}
        onChange={handleChange}
        toolbarConfig={toolbarConfig}
        className={`rich-text-editor ${name} ${
          error ? 'editor-error' : 'not-editor-error'
        }`} // Apply custom CSS class
      />
    </div>
  )
}

GlobalEditor.propTypes = {
  label: PropTypes.string, // Add label as a prop
  value: PropTypes.object.isRequired,
  onChange: PropTypes.func,
  error: PropTypes.bool,
}

export default GlobalEditor
