import React from 'react'
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'
import { useRecoilState } from 'recoil'
import './LoadingComponent.css'
import { loader } from '../../recoil/atoms/atoms'

//props, ref
function LoadingComponent() {
  const [loading, setLoading] = useRecoilState(loader)

  const handleClose = () => {
    setLoading(false)
  }

  return (
    <Backdrop
      id="LoaderOnIndexJS"
      className="loadingControl"
      open={loading}
      onClick={handleClose}
      sx={{ color: '#fff' }}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  )
}
export default LoadingComponent
